import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";

//Admin Pages
const Admin = lazy(()=> import("../components/Admin"))
const AdminDashboard = lazy(()=>import("../components/Admin/DashBoard"))
const AddNews = lazy(()=> import("../components/Admin/AddNews"))
const AdminAllNews = lazy(()=>import("../components/Admin/AllNews"))
const AdminLatestNews = lazy(()=>import("../components/Admin/LatestNews"))
const AdminSearchNews = lazy(()=>import("../components/Admin/SearchNews"))

//User Pages
const UserPage = lazy(()=>import("../components/User/index"))
const Privacy = lazy(()=>import("../components/Privacy"))

//Error Page
const ErrorPage = lazy(()=>import("../components/Error"))

//Loaders
const InPageLoader = lazy(()=>import("../components/Loader/InPageLoader"))
const FullScreenLoader = lazy(()=>import("../components/Loader/fullScreenLoader"))

const basename = process.env.REACT_APP_BASENAME;

const router = createBrowserRouter([
  {
    path: '/admin',
    element: (
      <Suspense fallback={<FullScreenLoader/>}>
          <Admin/>
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<FullScreenLoader/>}>
        <ErrorPage/>
      </Suspense>
    ),
    children:[
      {
        path:'/admin',
        element: (
          <Suspense fallback={<InPageLoader/>} key="adminAllNews">
            <AdminDashboard/>
          </Suspense>
        ),
      },
      {
        path: '/admin/allnews',
        element: (
          <Suspense fallback={<InPageLoader/>} key="adminAllNews">
            <AdminAllNews/>
          </Suspense>
        ),
      },
      {
        path: '/admin/latestnews',
        element: (
          <Suspense fallback={<InPageLoader/>} key="latestnews">
            <AdminLatestNews/>
          </Suspense>
        ),
      },
      {
        path: '/admin/addnews',
        element: (
          <Suspense fallback={<InPageLoader/>} key="adminAddNews">
            <AddNews/>
          </Suspense>
        ),
      },
      {
        path: '/admin/searchnews',
        element: (
          <Suspense fallback={<InPageLoader/>} key="searchnews">
            <AdminSearchNews/>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "",
    element: (
      <Suspense fallback={<FullScreenLoader/>}>
        <UserPage/>
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<FullScreenLoader/>}>
        <ErrorPage/>
      </Suspense>
    ),
  },
  {
    path: "/privacy",
    element: (
      <Suspense fallback={<FullScreenLoader/>}>
        <Privacy />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<FullScreenLoader/>}>
        <ErrorPage/>
      </Suspense>
    ),
  },
],
{
  basename: basename,
}
);

export default router;
